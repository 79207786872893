import {
    Drawer,
    Flex,
    Header,
    pxArrayToRem,
    pxToRem,
    useStyles,
    useTheme,
    useScrollLock,
    useAppDisplayModeContext,
} from '@archipro-design/aria';
import type { ReactNode, Ref } from 'react';
import { useRef } from 'react';
import * as S from './BottomDrawer.style';
import { useFacebookAutofill } from '~/modules/detail/hooks/useFacebookAutofill';

interface BottomDrawerProps {
    open: boolean;
    toggleModal: (value: boolean) => void;
    title?: ReactNode;
    children?: ReactNode;
    contentRef?: Ref<HTMLElement> | undefined;
    contentHeight?: number | string;
    className?: string;
}

const BottomDrawer = ({
    children,
    open,
    toggleModal,
    title,
    contentRef,
    contentHeight,
    className,
}: BottomDrawerProps) => {
    const theme = useTheme();
    const scrollRef = useRef<HTMLDivElement>(null);
    const appDisplayMode = useAppDisplayModeContext();
    const { css } = useStyles();

    useScrollLock(scrollRef, open);

    const heightTypeGuard = (value?: number | string) => {
        if (typeof value === 'number') {
            return pxToRem(value);
        } else if (typeof value === 'string') {
            return value;
        }
    };

    const drawerGestureBlocking = (e: React.TouchEvent<HTMLDivElement>) => {
        const scrollContainer = scrollRef?.current;
        if (scrollContainer) {
            const { scrollHeight, offsetHeight, scrollTop } = scrollContainer;
            const maxRange = scrollHeight - offsetHeight;
            const isScrolling =
                maxRange > 0 && 0 < scrollTop && scrollTop < maxRange;

            if (isScrolling) {
                e.stopPropagation();
            }
        }
    };

    const { mustKeepOpen } = useFacebookAutofill({ open });

    return (
        <Drawer
            contentRef={contentRef}
            open={open || mustKeepOpen}
            onOpenChange={(_, data) => toggleModal(!!data?.open)}
            anchor={'bottom'}
            fluid={true}
            className={className}
            variables={{
                contentBorderRadius: pxArrayToRem([12, 12, 0, 0]),
                contentHeight: contentHeight
                    ? heightTypeGuard(contentHeight)
                    : undefined,
                backdropColor: `rgba(0, 0, 0, 0.25)`,
            }}
            swipeableEdge={{
                onEdgeSwipe: (e, dir) => {
                    return dir === 'down' && toggleModal(false);
                },
            }}
            appDisplayMode={appDisplayMode}
            content={
                <Flex column onTouchMove={drawerGestureBlocking}>
                    {title && (
                        <Header
                            as="h3"
                            variables={{
                                ...theme.siteVariables.textStyles.Heading
                                    .Medium65,
                                fontSize: pxToRem(16),
                            }}
                            className={css(S.BottomDrawerTitle)}
                        >
                            {title}
                        </Header>
                    )}
                    <div
                        ref={scrollRef}
                        className={css(S.BottomDrawerContentContainer)}
                    >
                        <div>{children}</div>
                    </div>
                </Flex>
            }
        />
    );
};

export default BottomDrawer;
